import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesProfileServiceInterface from '~/src/components/profile/profile-layout/styles/StylesProfileServiceInterface'
import { PlatformUtils } from '@eo-storefronts/eo-core'

export default class StylesProfileServiceStylesTwo extends TemplateServiceStyleBase implements StylesProfileServiceInterface {
  public getPageSx(): SxProps {
    return {
      pt: 4,
      minHeight: '100%',
      [this.muiTheme.breakpoints.down('md')]: {
        p: 2,
        pt: this._getDownMdPtForPageSx()
      }
    }
  }

  private _getDownMdPtForPageSx() {
    if (!PlatformUtils().isCapacitorNative) {
      return 2
    }

    if (this.layout?.header.display) {
      return 2
    }

    return `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(3)})`
  }
}
