import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import StylesProfileServiceInterface from '~/src/components/profile/profile-layout/styles/StylesProfileServiceInterface'
import getProfileTemplateService from '~/src/components/profile/profile-layout/styles/GetProfileTemplateService'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

const useGetProfileTemplate = (): StylesProfileServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('profile')
  const layout = useEoValue(LAYOUT_STATE)

  useLayout('profile')

  return getProfileTemplateService(pageStyle, muiTheme, layout)
}

export default useGetProfileTemplate
