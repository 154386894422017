import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesProfileServiceInterface from '~/src/components/profile/profile-layout/styles/StylesProfileServiceInterface'
import StylesProfileServiceStylesTwo from '~/src/components/profile/profile-layout/styles/StylesProfileServiceStylesTwo'
import StylesProfileServiceStylesOne from '~/src/components/profile/profile-layout/styles/StylesProfileServiceStylesOne'
import { LayoutState } from '~/src/stores/layout'


const getProfileTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme, layout: LayoutState): StylesProfileServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesProfileServiceStylesTwo(muiTheme, undefined, layout)
    default:
      return new StylesProfileServiceStylesOne(muiTheme, undefined, layout)
  }
}

export default getProfileTemplateService
