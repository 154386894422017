import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesProfileServiceInterface from '~/src/components/profile/profile-layout/styles/StylesProfileServiceInterface'

export default class StylesProfileServiceStylesOne extends TemplateServiceStyleBase implements StylesProfileServiceInterface {
  public getPageSx(): SxProps {
    return {
      px: 4,
      py: 2,
      minHeight: '100%',
      [this.muiTheme.breakpoints.down('md')]: {
        px: 2,
        pb: 2,
        pt: 2
      },
      [this.muiTheme.breakpoints.down('sm')]: {
        p: 0,
        pb: 2,
        '.tabs-layout__content': {
          px: 2
        }
      }
    }
  }
}
